import Vue from 'vue'
import store from './store'
import VueRouter from 'vue-router'
import VueCropper from 'vue-cropper'
import ElementUI from 'element-ui'
import api from './api'
import inputCheck from '@utils/inputCheck.js'
import BaseUrlConfig from '@/http/config.js'
// import VueI18n from 'vue-i18n'
import { createI18n } from './lang' // i18n的配置文件
import rsa from '@utils/rsa'
import './styles/main.css'

const echarts = require('echarts')

Vue.prototype.$echarts = echarts
// Vue.use(ElementUI)
Vue.use(api)
Vue.use(VueCropper)
const cloneDeep = window.customPublicMethod.cloneDeep
for (const componentsName in window.commonComponents) {
  Vue.component(
    componentsName,
    cloneDeep(window.commonComponents[componentsName])
  )
}
Vue.use(inputCheck)
// 多语言处理
// Vue.use(VueI18n)
Vue.use(rsa)
Vue.config.productionTip = false

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')

// 文件路径 是否有子级 校验
const req = require.context('@/assets/svg', true, /\.svg$/)
const requireAll = (requireContent) => requireContent.keys().map(requireContent)
requireAll(req)

let router = null
let instance = null

Vue.directive('permission', {
  inserted: (el, binding) => {
    const operation = binding.value
    let permissionIdList = window.sessionStorage.getItem('permissionIdList')
    if (permissionIdList.length !== 0 && operation) {
      permissionIdList = JSON.parse(permissionIdList)
      console.log(
        binding.value,
        permissionIdList.findIndex((v) => v === operation)
      )
      if (permissionIdList.findIndex((v) => v === operation) === -1) {
        el.parentNode.removeChild(el)
      }
    }
  }
})

Vue.directive('none', {
  inserted: (el) => {
    if (el.children.length === 0) {
      el.parentNode.removeChild(el)
    }
  }
})

function render(props = {}) {
  // const nginxUrl = '/web-base/dataCenter'
  const nginxUrl = '/dataCenter'
  const {
    container,
    store: mainStore,
    messages,
    httpConfig,
    deleteConfirmationBox,
    map
  } = props
  BaseUrlConfig.initHttpConfig(httpConfig)
  if (mainStore) {
    mainStore.commit('globalLoadingFn', false)
    Vue.prototype.mainStore = Vue.observable(mainStore)
  }
  Vue.prototype.$deleteConfirmationBox = deleteConfirmationBox
  Vue.prototype.$map = map

  store.commit('setVersionConfig', mainStore.state.user.versionConfig)

  const routes = require('./router/index').default
  const App = require('./App.vue').default

  router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? nginxUrl : '/',
    // mode: 'history',
    mode: 'hash',
    routes
  })
  const i18n = createI18n(messages)
  Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
  })

  instance = new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App)
  }).$mount(container ? container.querySelector('#app') : '#app')
}

if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

// --------- 生命周期函数------------ //
export async function bootstrap() {
  console.log('[vue] vue app bootstraped')
}

export async function mount(props) {
  console.log('[vue] props from main framework', props)
  render(props)
}

export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  router = null
  instance = null
  Vue.prototype.$nextTick(() => {
    delete Vue.prototype.mainStore
    delete Vue.prototype.$deleteConfirmationBox
    delete Vue.prototype.$map
  })
}
