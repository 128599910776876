import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import '../public-path'

const { routes } = require(`@/version/${store.getters.versionConfig}.js`)
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default routes
