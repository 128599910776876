let contentObj = {}

if (typeof window === 'object') {
  const initI18n = require('@/lang')
  const i18n = initI18n.getI18n()

  /* 路由 */
  const routes = [
    {
      path: '*',
      redirect: '/index'
    },
    {
      path: '/',
      name: 'Home',
      redirect: '/index'
    },
    {
      path: '/index',
      name: 'index',
      meta: {
        label: i18n.t('alarmCenter')
      },
      component: () => import('@/views/dataCenter/dataCenter')
    }
  ]

  // 用户引导数据列表
  const userGuidanceList = [
    // left, left-center, left-bottom, top, top-center, top-right, right, right-center,right-bottom或 bottom, bottom-center, bottom-right, mid-center。
    {
      element: '#dataCenter',
      permission: 'dataCenter',
      popover: {
        title: i18n.t('homepage'),
        description: i18n.t('overviewOfKeyDataOfTheStatisticalPlatform'),
        position: 'bottom'
      }
    },
    {
      element: '#guide_alarmTypeFilter',
      popover: {
        title: i18n.t('alarmTypeFiltering'),
        description: i18n.t(
          'selectedTypesAreDisplayedOnTheDistributionMapUnselectedTypesAreNotDisplayedOnTheDistributionMaP'
        ),
        position: 'right'
      }
    },
    {
      element: '#guide_regionalPositioning',
      popover: {
        title: i18n.t('regionalPositioning'),
        description: i18n.t(
          'clickOnTheProvinceDistributionMapToLocateTheRegionOfTheProvinceYouClick'
        ),
        position: 'left'
      }
    }
  ]
  contentObj = {
    routes,
    userGuidanceList
  }
}
const componentsContent = {
  /* 指挥调度页面 */
  'map-of-china': 'map-of-china_dispatch',
  dataCenter: 'dataCenter_pipingGas'
}
/* 组件样式 */
const componentsStyle = {}

module.exports = {
  ...contentObj,
  componentsContent,
  componentsStyle
}
