import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui 的语言包，没用到的可以不引入
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN' // element-ui 的语言包，没用到的可以不引入
import enUS from './en_US.json' // 项目中的语言包 英文
import zhCN from './zh_CN.json' // 项目中的语言包 中文
Vue.use(VueI18n)

let i18n = null

export function getLanguage() {
  return window.localStorage.getItem('language') || 'zh_CN'
}

export function createI18n(config = {}) {
  const messages = {
    en_US: {
      ...config.en,
      ...elementEnLocale,
      ...enUS
    },
    zh_CN: {
      ...config.zh,
      ...elementZhLocale,
      ...zhCN
    }
  }
  // 获取当前语言环境：考虑到刷新操作，将语言类型存入缓存
  i18n = new VueI18n({
    locale: getLanguage(),
    messages
  })
  return i18n
}

export function getI18n() {
  if (i18n) {
    return i18n
  }
  return createI18n()
}
