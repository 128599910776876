let contentObj = {}

if (typeof window === 'object') {
  // const initI18n = require('@/lang')
  // const i18n = initI18n.getI18n()

  /* 路由 */
  const routes = [
    // {
    //   path: '/',
    //   name: 'Home',
    //   redirect: '/policeRecord'
    // },
    // {
    //   path: '/policeRecord',
    //   name: 'policeRecord',
    //   meta: {
    //     label: i18n.t('alarmRecord')
    //   },
    //   component: () => import('@/views/policeRecord/policeRecord')
    // },
    // {
    //   path: '/policeReport',
    //   name: 'policeReport',
    //   meta: {
    //     label: i18n.t('alarmReport')
    //   },
    //   component: () => import('@/views/policeReport/policeReport')
    // },
    // {
    //   path: '/dispatchRecord',
    //   name: 'dispatchRecord',
    //   meta: {
    //     label: i18n.t('dispatchRecord')
    //   },
    //   component: () => import('@/views/dispatchRecord/dispatchRecord')
    // }
  ]
  /* 侧边导航 */
  const menu = [
    // {
    //   label: i18n.t('alarmRecord'),
    //   permission: 'alert_policeRecord',
    //   name: 'policeRecord',
    //   path: '/policeRecord'
    // },
    // {
    //   label: i18n.t('alarmReport'),
    //   permission: 'alert_policeReport',
    //   name: 'policeReport',
    //   path: '/policeReport'
    // },
    // {
    //   label: i18n.t('dispatchRecord'),
    //   permission: 'alert_dispatchRecord',
    //   name: 'dispatchRecord',
    //   path: '/dispatchRecord'
    // }
  ]
  contentObj = {
    routes,
    menu
  }
}
module.exports = {
  ...contentObj
}
