let contentObj = {}

if (typeof window === 'object') {
  const initI18n = require('@/lang')
  const i18n = initI18n.getI18n()

  /* 路由 */
  const routes = [
    {
      path: '*',
      redirect: '/index'
    },
    {
      path: '/',
      name: 'Home',
      redirect: '/index'
    },
    {
      path: '/index',
      name: 'index',
      meta: {
        label: i18n.t('alarmCenter')
      },
      component: () => import('@/views/dataCenter/dataCenter')
    }
  ]
  contentObj = {
    routes
  }
}
const componentsContent = {
  /* 指挥调度页面 */
  'map-of-china': 'map-of-china_dispatch',
  dataCenter: 'dataCenter_shangrao'
}
/* 组件样式 */
const componentsStyle = {}

module.exports = {
  ...contentObj,
  componentsContent,
  componentsStyle
}
