let contentObj = {}

if (typeof window === 'object') {
  // const initI18n = require('@/lang')
  // const i18n = initI18n.getI18n()

  /* 路由 */
  const routes = [
    // {
    //   path: '/',
    //   name: 'Home',
    //   redirect: '/dailyCheck'
    // },
    // {
    //   path: '/dailyCheck',
    //   name: 'dailyCheck',
    //   meta: {
    //     label: i18n.t('attendance')
    //   },
    //   component: () => import('@/views/dailyCheck/dailyCheck')
    // },
    // {
    //   path: '/workTime',
    //   name: 'workTime',
    //   meta: {
    //     label: i18n.t('shifts')
    //   },
    //   component: () => import('@/views/workTime/workTime')
    // },
    // {
    //   path: '/checkReport',
    //   name: 'checkReport',
    //   meta: {
    //     label: i18n.t('reports')
    //   },
    //   component: () => import('@/views/checkReport/checkReport')
    // }
  ]
  /* 侧边导航 */
  const menu = [
    // {
    //   label: i18n.t('shifts'),
    //   permission: 'dailyCheck_workTime',
    //   name: 'workTime',
    //   path: '/workTime'
    // },
    // {
    //   label: i18n.t('attendance'),
    //   permission: 'dailyCheck_dailyCheck',
    //   name: 'dailyCheck',
    //   path: '/dailyCheck'
    // },
    // {
    //   label: i18n.t('reports'),
    //   permission: 'dailyCheck_checkReport',
    //   name: 'checkReport',
    //   path: '/checkReport'
    // }
  ]

  contentObj = {
    routes,
    menu
  }
}
const componentsContent = {}
/* 组件样式 */
const componentsStyle = {}
module.exports = {
  ...contentObj,
  componentsContent,
  componentsStyle
}
